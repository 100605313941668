@import './_style-overwrite';

@mixin rond {
  position:relative;	
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: var(--bleu);
  color: white;
  border: 1px solid var(--bleu);
  width: 7em;
  height: 7em;
  margin:0 auto 1em;
  span{
	  position:absolute;
	  top:50%; left:50%;
	  transform: translate(-50%, -50%);
	  margin:0;
  }
}

.color {
	color: var(--vert);
}
.chapo{
	font-weight: bold;
	color: var(--vert);
}
.figure{
	.figure-img {
		margin-bottom: 0;
	}	
	.blue-caption{
		background-color: var(--vert);
		padding: 0 0.5em;
		margin-bottom: 1em;
	}	
}
.ti-icon{
	vertical-align: middle;
}
.big{
	font-size: 5rem;
	font-weight: bold;
}
h3.big{
	font-size: 3rem;

}
.littlebigger{
	font-size: 1.2em;
}
.bigger{
	font-size: 1.3em;
	font-weight: bold;
}
.smaller{
	font-size: 0.7em;
}
.icone{
	height: 60px;
	width: 60px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
	display: inline-block;
	text-align: center;
	vertical-align: top;
}
.media-object,
h1{
	.icone{
		height: 45px;
		width: 45px;	
	}	
}
h2 .icone{
	height: 35px;
	width: 35px;
	margin: 0 0.2em;
}
.text-center .icone{
	margin: 0 auto;
}
.media{
	border-bottom: 1px solid var(--bleu);
	&:last-of-type {
  		border:none;
	}
	.media-left{
		padding-right: 1em;
	}
}

tfoot{
	font-weight: bold;
}
@media (min-width: 768px) {
	.figure{
		position: relative;
		.figure-img {
			margin-bottom: .5rem;
		}		
		.blue-caption{
			background-color: var(--vert);
			margin-top: -2rem;
			margin-bottom: 0;
			width: auto;
			display: inline-block;
			position: absolute;
			padding: 0 1.5em 0 0.5em;
			right: 0;
		}	
	}
}

.card.float-lg-right{
	margin-bottom: 1em;
}
/* Large devices */
@media (min-width: 992px) {
	.card.float-lg-right{
		margin-left: 1em;
	}
}

.flipped {
    transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
}
.btn{
	background-color: var(--vert);
	border-color: var(--vert);
	color: #fff;
	&:hover {
		color: darken(var(--vert), 10%);
		background-color: lighten(var(--vert), 20%);
	}
}
.btn-success {
	background-color: var(--bleu);
	border-color: var(--bleu);

	&:hover {
		background-color: var(--bleu);
		border-color: var(--bleu);
	}
}
.btn-warning{
	background-color: var(--col4);
	border-color: var(--col4);
}
#soutenir .btn-warning{
	font-weight: bold;
}

.navigation,
.counters-item{
	background-color: white;
}
.bg-1 {
	background-color: var(--col_bg1);
}

.bg-2 {
	background-color: var(--col_bg2);
}
.bg-one,{
	background-color: var(--col_bg3);
}
.bg-bleu,
.bg-vert,
.bg-vert2,
.bg-dark,
.bg-6{
	background-color:var(--bleu);
	color: white;
	.title h2,
	em{
		color: white;
		span.color{
			color: var(--col3);
		}
	}
	h3,
	h4,
	.blockquote-footer{
		color: white;
	}
	a{
		color: white;
		text-decoration: underline;
		&:hover{
			color: var(--vert);
		}
	}
	.card,
	.bg-white{
		color: var(--col2);
		em{
			color: var(--col2);
		}
		a{
			color: var(--col1);
			&.btn-primary{
				color:white;
			}
		}
	}
	.bg-white,
	.bg-1{
		color: var(--col1);
	}
}
.bg-dark{
	background-color: var(--col_bgdark) !important;
}
.bg-vert{
	background-color: var(--vert);
	.bg-1{
		color: var(--vert);
		h3{
			color: var(--vert);
		}
	}
}
.bg-vert2{
	background-color: var(--vert2);
}
.bg-6{
	background-color: var(--col6);
}
.navbar-brand img{
	max-width: 200px;
}
.nav-item.haschildren{
	display: block;
	position: relative;
	.caret{
		color: red;
	}
}
.numberCircle {
    border-radius: 50%;
    color: inherit;
    border: 2px solid var(--bleu);
    text-align: center;
    font-weight: bold;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 1.9;
}
.gros{
	font-size: 2rem;
	font-weight: bold;
}
.credit{
	font-size: 0.8em;
	text-align: right;
}
.block-col5,
.block-bleu {
	background-color: var(--bleu);
	color: white;
	h2{
		background-color: white;
		padding: 5px;
		color: var(--bleu);
		a{
			color: var(--bleu);
		}
	}
	h3,h4,h5{
		color: white;
	}
	a{
		color: white;
		text-decoration: underline;
		&:hover{
			color:var(--vert);
		}
	}
}
.block-col5{
	background-color: var(--col5);
	h2{
		color: var(--col5);
		a{
			color: var(--col5);
		}
	}
}
.bordbleu{
	border: 2px solid var(--bleu);
}
.centrer{
	text-align: center;
	.aligner {
		text-align: left;
		margin: 1.5em auto 0;
		width: auto;
		display: inline-block;
	}
}
.bleu{
	color: var(--bleu);
	.rond {
	  background-color: var(--bleu);
	  border-color: var(--bleu);  
	}
	h3{
		color: var(--bleu);
	}
}
.vert{
	color: var(--vert);
}
.dark{
	color: var(--coldark);
}
.col4{
	color: var(--col4);	
}
.col5{
	color: var(--col5);	
}
.col6{
	color: var(--col6);	
}

#mot-intro{
	.card-body{
		padding: 0.25rem;
		color: white;
		background-color: var(--vert);
		font-size: small;
		h5{
			color: white;
			margin: 0;
			font-size: 1em;
			font-weight: bold;

		}
	}
}
.modal .modal-content .modal-header {
	display: block;
	border: none;
}
.portfolio-block{
	.card{
		width: 100%;
		height: auto;
	}	
	.caption{
		background-color: white;
		width: 100%;
		text-align: left;
		img{
			width: auto;
			height: auto;
		}	
	}
}
.slick-arrow{
	text-align: center;
	padding-top: 2rem;
	.prev,
	.next {
		color: white;
		font-size: 1.2rem;
		font-weight: bold;
		background-color: var(--vert);
		border: none;
		border-radius: 50px;
		padding: 1em;
	}
	.prev {
	    left: 100px;
	}
	.next {
	    right: 100px;
	}
}

.bandeau{
	.section ~ .titrecol12.section {
		padding-top: 0;    
	}
	.titrecol12.section {
		padding-bottom: 0;    
	}
}

.comptes{
	.bull{
		color: var(--vert);
		font-size: 3rem;
		font-weight: bold;
		vertical-align: middle;
		padding: 0 3px 2px 0;
		line-height: 1;
	}	
	.table.lignesinterieur{
		tbody{
			tr:first-child td {
	    		border: none;
			}
			td{
				padding: 0;
				vertical-align: middle;
			}
		}
		tbody,
		tfoot{
			tr td:first-child  {
				border-right: 1px solid #dee2e6;
			}
			
		}
		.bull{
			padding-right: 5px;
		}
	}

}