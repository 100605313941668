:root {
  --vert: #40a102;
  --vert2: #06aa80;
  --vert3: #7ab800;
  --bleu: #2e8fb7;
  --coltxt: #606060;  
	--coldark: #2f2021;
  --col1: #606060; /*darker grey*/
  --col2: grey;
  --col3: #D3D3D3; /*light grey*/
  --col4: #74CAF2; /*bleu claire*/
  --col5: #82338b; /*mauve*/
  --col6: #00929f; /*turquoise*/
  --col_bg1: #f6f6f6; /*gris très claire*/
  --col_bg2: #E7E7E7; /*gris claire 2*/
  --col_bg3: #efefef; /*gris claire 1 */
  --col_bgdark: var(--coldark);

  --font1: "Open Sans", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
  --font2: Roboto, var(--font1);
  --font3: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


html {
	background-color: var(--col_bg1);
	color: var(--col1);
}

body {
	background-color: var(--col_bg1);
	font-family: var(--font2);
	color: var(--col1);
	-webkit-font-smoothing: antialiased;
	font-size: 1.1rem;
}
body,
p {
	font-size: 1.3rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	font-family: var(--font1);
	color: var(--vert);
	a{
		color: inherit;
	}
}
h2,
h4,
h6 {
	color: var(--bleu);
}

p {
	font-family: var(--font1);
}

[scroll-behavior=smooth] {
	scroll-behavior: smooth;
}

pre {
	color: var(--vert);
}

::selection {
	color: var(--col2);
	background: var(--vert);
}

hr {
	border-top: 1px solid var(--vert);
}

figure {
	margin: 0;
}

a {
	color: var(--bleu);
	transition: all .3s ease-in 0s;
}

iframe {
	border: 0;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	outline: 0;
}

a:focus,
a:hover {
	color: #6cb670;
}

.kill-margin-bottom {
	margin-bottom: 0!important;
}

.padding-0 {
	padding: 0!important;
}

.mb-50 {
	margin-bottom: 50px;
}

.btn {
	background-color: transparent;
	color: var(--col2);
	padding: 10px 30px;
	border-radius: 0;
	transition: all .3s ease-in 0s;
	&.btn-sm{
		padding: 0.2em 0.3em;
	}
	&:focus {
		color: var(--col3);
	}
}

.btn-transparent {
	border: 1px solid var(--col3);
}

.btn-transparent:hover,
.btn-transparent:focus {
	background-color: var(--vert);
	border: 1px solid transparent;
	color: var(--col2);
}

#loading-mask {
	background: #333439;
	height: 100%;
	left: 0;
	opacity: 1;
	filter: alpha(opacity=100);
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 999999999;
}

.loading-img {
	height: 100%;
	left: 0;
	position: fixed;
	top: 50%;
	width: 100%;
}

.border-meghna {
	border-top: 2px solid var(--col3);
	height: 1px;
	margin: 15px auto 0;
	position: relative;
	width: 30%;
}

.border-meghna:before {
	background-color: var(--col2);
	content: '';
	height: 6px;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	top: -4px;
	width: 50px;
	background-color: var(--vert);
}

.sub-title {
	padding: 0 0 50px;
}

.sub-title>h3 {
	border-left: 3px solid #ddd;
	border-right: 3px solid #ddd;
	display: inline-block;
	padding: 5px 25px;
	text-transform: uppercase;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
	outline: 0;
}

.bg-one {
	background-color: var(--col_bg1);
}
.section-bg {
	background: var(--col_bg3);
}

.bg-1,
.bg-2 {
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-2 {
	background-attachment: fixed;
}


.parallax-section {
	background-attachment: fixed!important;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.no-padding {
	padding: 0!important;
}

.inline-block {
	display: inline-block;
}

figure {
	margin: 0;
}

a {
	transition: all .2s ease-in 0s;
}

iframe {
	border: 0;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	outline: 0;
}

a:focus,
a:hover {
	color: var(--vert);
}
.member-photo .mask ul li a {
	border: none;
	font-size: 60px;
	&:hover {
	  background-color: transparent;
	  border: none;
	}
}

.mt-20 {
	margin-top: 20px;
}

.section {
	padding: 80px 0;
}

@media(max-width:480px) {
	.section {
		padding: 30px 0
	};
}

@media(max-width:768px) {
	.section {
		padding: 40px 0
	};
}

.section-sm {
	padding: 70px 0;
}

.section-xs {
	padding: 50px 0;
}

.btn:focus {
	color: #ddd;
}

#home {
	position: relative;
}


.parallax-section {
	background-attachment: fixed;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--col2);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sk-cube-grid {
	width: 40px;
	height: 40px;
	margin: 100px auto;
}

.sk-cube-grid .sk-cube {
	width: 33%;
	height: 33%;
	background-color: var(--vert);
	float: left;
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

.sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: .4s;
	animation-delay: .4s;
}

.sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s;
}

.sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

.sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s;
}

.sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1)
	}
	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1)
	};
}

@keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1)
	}
	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1)
	};
}

.title {
	padding: 60px 0;
	h2 {
		text-transform: none;
		color: var(--col2);
		font-size: 3em;
		span {
			color: var(--vert);
			&.col5{
				color: var(--col5);	
			}
			&.col6{
				color: var(--col6);	
			}
		}
	}
	p {
		color: #666;
	}
	&.title-white h2 {
		color: var(--col2);
	}

}






.overly {
	position: relative;
}

.overly:before {
	content: '';
	background: rgba(0, 0, 0, .0);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.header-bradcrumb {
	background: transparent;
}

.header-bradcrumb a,
.header-bradcrumb .active {
	color: #cfcfcf;
	font-weight: 200;
	font-family: var(--font1);
}

.header-bradcrumb a:hover {
	color: var(--vert);
}

.slick-slide {
	outline: none;
	img{
		margin: 0 auto;
	}
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	transition: all .3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: .8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

.logo {
	margin-top: 10px;
	display: inline-block;
	img{
		height: 5em;
	}
}

.single-page-header {
	background-image: url(../images/about/about-header.jpg);
	background-size: cover;
	padding: 140px 0 70px;
	text-align: center;
	color: var(--col2);
	position: relative;
}

.single-page-header:before {
	background: rgba(0, 0, 0, .5);
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.navigation {
	background-color: #1d2024;
	width: 100%;
	margin-bottom: 0;
}

.navigation .logo {
	padding: 0;
}

.navigation .navigation-menu li a.active {
	color: var(--vert);
}

.navigation .navigation-menu li a {
	font-size: 16px;
	color: var(--col1);
}

.navigation .navigation-menu li a:hover,
.navigation .navigation-menu li a:focus {
	background: transparent;
	color: var(--vert);
}

.navigation .navbar-toggler {
	color: var(--col2);
	border:none;
	background-color: transparent;
	font-size: 2em;
	padding-top: 0;
	&:hover{
		color:var(--vert);
	}
	&:focus{
		outline-color: var(--vert);
	}
	.navbar-collapse {
		padding: 10px;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
		border-top: 5px solid transparent;
	}
}

@media(min-width:768px) {
	.navigation .navbar-collapse {
		box-shadow: none;
		border-top: none
	};
}

.hero-area {
	background-size: cover;
	background-position: center;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	&:before {
		content: '';
		background: rgba(255, 255, 255, 0.0);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.block {
		text-align: center;
		z-index: 99;
		padding: 1em;
		margin: 2em;
		background: rgba(0, 0, 0, 0.2);
		img.logo{
			max-width: 100%;
			background: #fff;
			display: inline-block;
			height: 160px;
			width: 160px;
			border-radius: 80px;
		}
		.video-button {
			height: 160px;
			width: 160px;
			border-radius: 80px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100px;	
		}
		.vignette {
			height: 160px;
			width: 160px;
		}
		h1 {
			text-transform: none;
			color: white;
		}
		h2 {
			font-size: 4em;
			width: 100%;
			color: white;
		}
a .ti-angle-double-down::before{
	color: white;
	font-weight: bold;
	font-size: 2em;
	}
}
}
@media(max-width:480px) {
	.hero-area .block{
		h1 {
			font-size: 60px; 
		}
		h2 {
			font-size: 30px;
		}        
	}
}

.about .block {
	background: var(--col_bg2);
}
.about .block:hover {
  border-bottom: 2px solid var(--col4);;
}

.about .block .icon-box{
border-left: 2px solid var(--col3);
border-right: 2px solid var(--col3);
}


.about .block .icon-box:after,
.about .block .icon-box:before {
	content: "";
	position: absolute;
	z-index: 1;
	width: 113.14px;
	height: 113.14px;
	background-color: inherit;
	left: 20.4315px;
	-webkit-transform: scaleY(0.5774) rotate(-45deg);
	transform: scaleY(0.5774) rotate(-45deg);
}

.about .block .icon-box:before {
	top: -56.5685px;
	border-top: 2px solid var(--col3);
	border-right: 2px solid var(--col3);
}

.about .block .icon-box:after {
	bottom: -56.5685px;
	border-bottom: 2px solid var(--col3);
	border-left: 2px solid var(--col3);
}

.about .block .icon-box h3 {
	color: var(--vert);
}

.content-block{
	padding: 40px;
}

.content-block.media {
	margin-top: 25px;
}

.content-block.media .pull-left {
	padding-right: 15px;
}

.content-block.media i {
	font-size: 30px;
	color: var(--vert);
}

.content-block.media h4 {
	font-size: 16px;
	font-weight: 600;
}

.call-to-action {
	h2,
	p,
	a{
		color: white;		
	}
}

.btn-main {
	margin-top: 20px;
}

.contact-us {
	padding-top: 50px;
}

@media(max-width:768px) {
	.contact-us {
		padding-top: 80px
	};
}

.contact-form {
	margin-bottom: 60px;
}

.form-meghna .form-control {
	background-color: transparent;
	border-radius: 0;
	padding: 5px 10px;
	color: #ddd;
	border: 1px solid var(--col3);
}

.form-meghna .form-control:focus {
	border-color: none;
	box-shadow: none;
}

.form-meghna input:hover,
.form-meghna textarea:hover,
#contact-submit:hover {
	border-color: var(--vert);
}

.form-meghna::placeholder {
	color: #ddd!important;
}

#contact-submit {
	border: 1px solid var(--col3);
	background: var(--vert);
	padding: 12px 0;
	width: 100%;
	margin: 0;
}

.google-map #map-canvas {
	height: 400px;
}

.contact-info p {
	margin-bottom: 25px;
}

.con-info {
	margin-bottom: 20px;
}

.con-info i,
.con-info span {
	padding-right: 5px;
}

.error {
	display: none;
	padding: 10px;
	color: #d8000c;
	border-radius: 4px;
	font-size: 13px;
	background-color: #ffbaba;
}

.success {
	background-color: #6cb670;
	border-radius: 4px;
	color: var(--col2);
	display: none;
	font-size: 13px;
	padding: 10px;
}
.service-block {
	padding: 20px;
	border-left: 1px solid var(--col3);
	border-top: 1px solid var(--col3);
	margin-bottom: 40px;
	box-shadow: 2px 2px 5px rgba(44, 44, 44, .3), 13px 13px 0 rgba(255, 255, 255, .7);

}
.member-photo {
	overflow: hidden;
	position: relative;
	img {
		transition: all .4s ease 0s;
	}    
	&:hover img {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	.mask {
		background-color: rgba(46, 134, 183, .4);
	}
}
@media(max-width:768px) {
	.member-photo {
		text-align: center;
		img {
			display: inline-block
		};        
	};
}

.member-meta {
	padding: 15px;
	h4 {
		margin: 0 0 5px;
	}
}


.post-block {
	background-color: white;
	margin-bottom: 10px;
	border: 1ps solid var(--col2);
	.content {
		padding: 20px;
		font-size: 18px;
		padding-left: 1.1em;        
		h3 {
			margin: 0 0 6px;
			font-size: 20px;
			line-height: 1.5;
			text-transform: none;
		}        
	}
}

.all-post {
	margin-top: 50px;
}
.social-profile ul li {
	margin: 0 10px 0 0;
	display: inline-block;
}

.social-profile ul li a {
	color: #4e595f;
	display: block;
	font-size: 16px;
}

.social-profile ul li a i:hover {
	color: var(--vert);
}

.comments-section {
	margin-top: 35px;
}

.author-about {
	margin-top: 40px;
}

.post-author {
	margin-right: 20px;
}

.post-author>img {
	border: 1px solid #dedede;
	max-width: 120px;
	padding: 5px;
	width: 100%;
}
#staticman-form:before {
	display: block;
	height: 90px;
	margin-top: -90px;
	visibility: hidden;
	content: "";
}

@media(max-width:768px) {
	#staticman-form:before {
		display: block;
		height: 80px;
		margin-top: -80px;
		visibility: hidden;
		content: ""
	};
}

.social-icon ul li {
	a {
		background-color: var(--col3);
		i {
			color: var(--col1);
		}        
	}
	&:hover a {
		background-color: var(--vert);
		i {
			color: white;
		}
	}        
}

@media(max-width:768px) {
	.social-icon ul li a i {
		line-height: 55px;
		font-size: 25px
	};
}

.copyright {
	padding: 20px 0;
	color: var(--col2);
	font-size: .8em;
	img {
		margin-bottom: 15px;
		height: 7vw;
	}    
}

#scrollUp {
	border: 1px solid #4e595f;
	bottom: 15px;
	color: var(--col2);
	display: none;
	padding: 8px 12px;
	position: fixed;
	right: 20px;
	z-index: 600;
}

#scrollUp:hover {
	background-color: var(--vert);
	border: 1px solid var(--vert);
}

#twitter-feed {
	background-image: url(../images/parallax/twitter.jpg);
	padding-bottom: 100px;
	padding-top: 80px;
}

.twitter-bird span {
	border: 2px solid #6cb670;
	border-radius: 50px;
	color: #6cb670;
	display: inline-block;
	margin-bottom: 50px;
	height: 90px;
	width: 90px;
}

.twitter-bird span i {
	line-height: 90px;
}

.tweet {
	margin-bottom: 10px;
}

.tweet a {
	color: #6cb670;
}

#blog-banner {
	padding-bottom: 100px;
	padding-top: 150px;
}

.blog-icon {
	border: 1px solid #6cb670;
	border-radius: 50%;
	color: #6cb670;
	display: inline-block;
	padding: 16px 20px;
}

.blog-title h1 {
	text-transform: uppercase;
}

#blog-page {
	margin: 0 0 40px;
}

.post-item .entry {
	border: 1px solid #4e595f;
	margin-bottom: 30px;
}

.entry .post-excerpt {
	padding: 0 20px;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev,
#gallery-post .owl-next,
#post-thumb .owl-next {
	padding: 5px 17px;
	position: absolute;
	top: 45%;
	transition: all .5s ease-in 0s;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev {
	left: -45px;
}

#gallery-post .owl-next,
#post-thumb .owl-next {
	right: -45px;
}

#gallery-post:hover .owl-prev,
#post-thumb:hover .owl-prev {
	left: 0;
}

#gallery-post:hover .owl-next,
#post-thumb:hover .owl-next {
	right: 0;
}

#gallery-post,
#post-thumb {
	overflow-x: hidden;
}

#post-thumb .owl-buttons {
	color: var(--col2);
}

.post-meta {
	background-color: #3d444a;
	border-top: 1px solid #4e595f;
	font-size: 13px;
	margin: 20px 0 0;
	padding: 0 20px 10px;
}

.post-meta>span {
	border-right: 1px solid #4e595f;
	display: inline-block;
	font-size: 12px;
	margin: 10px 10px 0 0;
	padding-right: 10px;
}

.post-meta>span:last-child {
	border-right: 0;
}

.post-meta>span>i {
	margin-right: 5px;
}

.post-meta a:hover {
	color: #6cb670;
}

.post-excerpt .more {
	margin-top: 20px;
}

.post-excerpt .more a {
	display: inline-block;
	font-size: 13px;
	padding: 10px 25px;
}

.post-excerpt .more a:hover {
	background-color: #6cb670;
	color: var(--col2);
}

aside.widget {
	margin-bottom: 25px;
}

.widget-title>h3 {
	color: var(--col2);
	margin-bottom: 15px;
	margin-top: 0;
	position: relative;
}

#search-form {
	position: relative;
}

#search-submit {
	background: 0 0;
	border: 0;
	color: #c8c8ca;
	display: block;
	font-size: 16px;
	height: 32px;
	outline: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 32px;
}

.widget-content .tab-content {
	background-color: #2f383d;
	padding: 20px;
}

.tab-post-nav {
	background-color: #4a535a;
}

.tab-post-nav li {
	float: left;
}

.tab-post-nav li.active a {
	border-top: 3px solid #6cb670;
	background-color: #2f383d;
	color: #6cb670;
}

.tab-pane.active .clearfix:first-child {
	margin-bottom: 25px;
}

.tab-excerpt>span {
	display: block;
	font-size: 12px;
	margin-bottom: 10px;
}

.tab-excerpt h4 a {
	color: var(--col2);
}

.tab-excerpt h4 a:hover {
	color: #6cb670;
}

.tab-post-nav li a {
	border-top: 3px solid transparent;
	color: var(--col2);
	display: block;
	padding: 13px 18px;
	text-align: center;
}

.categories li {
	margin-bottom: 10px;
	transition: all .5s ease-in 0s;
}

.categories>li:hover a {
	padding-left: 10px;
	color: #6cb670;
}

.categories>li:last-child {
	border-bottom: 0;
}

.categories li a {
	padding-left: 5px;
}

.widget-content .tag {
	background-color: #2f383d;
	color: var(--col2);
	display: inline-block;
	margin-bottom: 10px;
	margin-right: 5px;
	padding: 5px 13px;
}

.widget-content .tag:hover {
	background-color: #6cb670;
	color: var(--col2);
}

.post-pagination {
	display: inline-block;
	margin-bottom: 20px;
}

.post-pagination ul li {
	margin-right: 5px;
	float: left;
}

.post-pagination ul li:last-child {
	margin-right: 0;
}

.post-pagination ul li.disabled a {
	cursor: not-allowed;
}

.post-pagination ul li.disabled a:hover {
	background-color: #2e373c;
	color: #9aa8b3;
}

.post-pagination ul li a {
	background-color: #30393e;
	color: #9aa8b3;
	display: block;
	padding: 8px 18px;
}

.post-pagination ul li a:hover,
.post-pagination ul li.active a {
	background-color: #6cb670;
	color: var(--col2);
}

.single-blog {
	background-color: var(--col2);
	margin-bottom: 50px;
	padding: 20px;
}

blockquote {
	border: none;
}

.next-prev {
	border-bottom: 1px solid #4e595f;
	border-top: 1px solid #4e595f;
	margin: 20px 0;
	padding: 15px 0 10px;
}

.next-prev a:hover {
	color: #6cb670;
}

.prev-post i {
	float: left;
	margin-right: 10px;
	margin-top: -4px;
}

.next-post i {
	float: right;
	margin-left: 10px;
	margin-top: -3px;
}

.post-author {
	margin-right: 20px;
}

.post-author>img {
	border: 1px solid #4e595f;
	max-width: 120px;
	padding: 5px;
	width: 100%;
}

.comment-reply-form>h3,
.author-about>h4,
.comments>h4 {
	border-bottom: 1px solid #4e595f;
	margin-bottom: 15px;
	margin-top: 0;
	padding-bottom: 10px;
	position: relative;
}

.author-bio h5 {
	display: inline-block;
}

.post-item .comments-section {
	margin-top: 35px;
}

.comment-list ul {
	margin-top: 20px;
}

.comment-list li {
	margin-bottom: 20px;
}

.comment-wrap {
	border: 1px solid #4e595f;
	border-radius: 1px;
	margin-left: 20px;
	padding: 10px;
	position: relative;
}

.comment-wrap .author-avatar {
	margin-left: -25px;
	margin-right: 20px;
}

.author-comment {
	margin-bottom: 15px;
	overflow: hidden;
}

.author-comment cite a:hover,
.replay:hover {
	color: #6cb670;
	text-decoration: underline;
}

.author-comment cite {
	margin-bottom: 2px;
	padding-top: 4px;
}

.comment-content {
	margin-left: 45px;
}

.comment-list ul li {
	padding-left: 25px;
}

.comment-reply-form {
	margin-top: 80px;
}

#comment-form {
	margin-top: 35px;
}

#comment-form .form-control:hover {
	border-color: #6cb670;
}

.form-group:last-child,
.form-group:last-child input {
	margin: 0;
}

.form-control-meghna .form-control {
	background-color: transparent;
	padding: 5px 10px;
	color: #ddd;
	border: 1px solid var(--col3);
}

.form-control-meghna .form-control:focus {
	border-color: var(--vert);
	box-shadow: 0 0 0 .2rem rgba(0, 174, 255, .432);
}

figure img {
	display: block;
	margin: 0 auto 5px;
}

figcaption {
	text-align: center;
}


/*!Social sharing buttons from Sharingbuttons.io*/

.resp-sharing-button__link,
.resp-sharing-button__icon {
	display: inline-block;
}

.resp-sharing-button__link {
	text-decoration: none;
	color: var(--col2);
	margin: .5em;
}

.resp-sharing-button {
	border-radius: 5px;
	transition: 25ms ease-out;
	padding: .5em .75em;
	font-family: var(--font3);
}

.resp-sharing-button__icon svg {
	width: 1em;
	height: 1em;
	margin-right: .4em;
	vertical-align: top;
}

.resp-sharing-button--small svg {
	margin: 0;
	vertical-align: middle;
}

.resp-sharing-button__icon {
	stroke: #fff;
	fill: none;
}

.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
	fill: #fff;
	stroke: none;
}

.resp-sharing-button--twitter {
	background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
	background-color: #2795e9;
}

.resp-sharing-button--pinterest {
	background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
	background-color: #8c0615;
}

.resp-sharing-button--facebook {
	background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
	background-color: #2d4373;
}

.resp-sharing-button--tumblr {
	background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
	background-color: #222d3c;
}

.resp-sharing-button--reddit {
	background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
	background-color: #3a80c1;
}

.resp-sharing-button--google {
	background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
	background-color: #c23321;
}

.resp-sharing-button--linkedin {
	background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
	background-color: #046293;
}

.resp-sharing-button--email {
	background-color: #777;
}

.resp-sharing-button--email:hover {
	background-color: #5e5e5e;
}

.resp-sharing-button--xing {
	background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
	background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
	background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
	background-color: #1da851;
}

.resp-sharing-button--hackernews {
	background-color: #f60;
}

.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
	background-color: #fb6200;
}

.resp-sharing-button--vk {
	background-color: #507299;
}

.resp-sharing-button--vk:hover {
	background-color: #43648c;
}

.resp-sharing-button--facebook {
	background-color: #3b5998;
	border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
	background-color: #2d4373;
	border-color: #2d4373;
}

.resp-sharing-button--twitter {
	background-color: #55acee;
	border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
	background-color: #2795e9;
	border-color: #2795e9;
}

.resp-sharing-button--google {
	background-color: #dd4b39;
	border-color: #dd4b39;
}

.resp-sharing-button--google:hover,
.resp-sharing-button--google:active {
	background-color: #c23321;
	border-color: #c23321;
}

.resp-sharing-button--email {
	background-color: #777;
	border-color: #777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
	background-color: #5e5e5e;
	border-color: #5e5e5e;
}

.resp-sharing-button--reddit {
	background-color: #5f99cf;
	border-color: #5f99cf;
}

.resp-sharing-button--reddit:hover,
.resp-sharing-button--reddit:active {
	background-color: #3a80c1;
	border-color: #3a80c1;
}

.resp-sharing-button--whatsapp {
	background-color: #25d366;
	border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
	background-color: #1da851;
	border-color: #1da851;
}

.resp-sharing-button--telegram {
	background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
	background-color: #4b97d1;
} 


